export const ROUTES_SERVICE_FIRMWARE = 'service-firmware'
export const ROUTES_SERVICE_CUSTOMERS = 'service-customers'
export const ROUTES_SERVICE_TARIFFS = 'service-tariffs'
export const ROUTES_MODULES = 'modules'
export const ROUTES_MODULE = 'module'
export const ROUTES_INSTALLATIONS = 'installations'
export const ROUTES_INSTALLATION = 'installation'
export const ROUTES_BUS_CONFIGURATIONS = 'bus-configurations'
export const ROUTES_BUS_CONFIGURATION = 'bus-configuration'
export const ROUTES_BUS_CONFIGURATION_CREATE = 'bus-configuration-create'
export const ROUTES_DEVICE_TYPES = 'device-types'
export const ROUTES_DEVICE_TYPE = 'device-type'
export const ROUTES_DEVICE_TYPE_CREATE = 'device-type-create'
export const ROUTES_LOGIN = 'login'
export const ROUTES_SETTINGS = 'settings'
export const ROUTES_CLOUD_ADAPTERS = 'cloud-adapters'
export const ROUTES_SETTINGS_USERS = 'settings-users'
export const ROUTES_CLOUD_ADAPTER = 'cloud-adapter'
export const ROUTES_CLOUD_ADAPTER_CREATE = 'cloud-adapter-create'
export const ROUTES_ADMINISTRATION_USER = 'administration-user'
export const ROUTES_ADMINISTRATION_USER_CREATE = 'administration-user-create'
export const ROUTES_ADMINISTRATION_SERVICE_DESK = 'administration-service-desk'
export const ROUTES_ADMINISTRATION_LABELS = 'administration-labels'
export const ROUTES_ADMINISTRATION_AUDIT_LOG = 'administration-audit-log'
export const ROUTES_MAIN_USER = 'main-user'
export const ROUTES_ONBOARDING = 'onboarding'
export const ROUTES_ONBOARDING_ERROR = 'onboarding-error'
export const ROUTES_GENERAL_INFORMATION = 'general-information'
export const ROUTES_API_DOCUMENTATION = 'general-public-api-doc'
export const ROUTES_CONSUMPTION_DEBUG = 'service-consumption-debug'
export const ROUTES_ERROR_DEBUG = 'service-error-debug'
export const ROUTES_STORAGE_ERROR = 'storage-error'

export const ROUTES_ONBOARDING_DEBUG = 'service-onboarding-debug'
export const ROUTES_SERIAL_DEBUG = 'modbus-serial-debug'
