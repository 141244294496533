import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { constants } from '@/globals'

export default {
  path: 'administration',
  alias: 'settings',
  children: [
    {
      path: 'users',
      component: retriableLazyImport(() => import('./pages/users/pages/Overview.vue')),
      name: constants.ROUTES_SETTINGS_USERS,
      meta: {
        navigation: {
          labelKey: 'app.settings.pages.users.pages.overview.title',
          targetName: constants.ROUTES_SETTINGS_USERS,
        },
        permission: {
          scope: 'read:customer_user',
        },
      },
    },
    {
      path: 'users/:userId',
      component: retriableLazyImport(() => import('./pages/users/pages/Details.vue')),
      name: constants.ROUTES_ADMINISTRATION_USER,
      meta: {
        permission: {
          scope: 'read:customer_user',
        },
      },
    },
    {
      path: 'users/create',
      component: retriableLazyImport(() => import('./pages/users/pages/Details.vue')),
      name: constants.ROUTES_ADMINISTRATION_USER_CREATE,
      meta: {
        permission: {
          scope: 'write:customer_user',
        },
      },
    },
    {
      path: 'service-desk',
      component: retriableLazyImport(() => import('./pages/service-desk/pages/Overview.vue')),
      name: constants.ROUTES_ADMINISTRATION_SERVICE_DESK,
      meta: {
        navigation: {
          labelKey: 'app.settings.pages.service_desk.pages.overview.title',
          targetName: constants.ROUTES_ADMINISTRATION_SERVICE_DESK,
        },
        permission: {
          scope: 'read:customer',
        },
      },
    },
    {
      path: 'audit-log',
      component: retriableLazyImport(() => import('./pages/audit-log/pages/Overview.vue')),
      name: constants.ROUTES_ADMINISTRATION_AUDIT_LOG,
      meta: {
        navigation: {
          labelKey: 'app.audit_log.pages.overview.title',
          targetName: constants.ROUTES_ADMINISTRATION_AUDIT_LOG,
        },
        permission: {
          scope: 'read:audit_entry',
        },
      },
    },
    {
      path: 'labels',
      component: retriableLazyImport(() => import('./pages/labels/pages/Overview.vue')),
      name: constants.ROUTES_ADMINISTRATION_LABELS,
      meta: {
        navigation: {
          labelKey: 'labels.overview.title',
          targetName: constants.ROUTES_ADMINISTRATION_LABELS,
        },
        permission: {
          scope: 'write:label',
        },
      },
    },
  ],
} as RouteRecordRaw
