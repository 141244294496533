import { retriableLazyImport } from '@gcx-si/vue-components/retriableLazyImport'

import type { RouteRecordRaw } from 'vue-router'

import { constants } from '@/globals'

export default {
  path: 'system-types',
  alias: 'bus-configurations',
  meta: {
    navigation: {
      labelKey: 'app.bus_configuration.pages.overview.title',
      targetName: constants.ROUTES_BUS_CONFIGURATIONS,
    },
  },
  children: [
    {
      path: '',
      component: retriableLazyImport(() => import('./pages/Overview.vue')),
      name: constants.ROUTES_BUS_CONFIGURATIONS,
      meta: {
        permission: {
          scope: 'read:system_type',
        },
      },
    },
    {
      path: ':busConfigurationId',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_BUS_CONFIGURATION,
      meta: {
        permission: {
          scope: 'read:system_type',
        },
      },
    },
    {
      path: 'create',
      component: retriableLazyImport(() => import('./pages/Details.vue')),
      name: constants.ROUTES_BUS_CONFIGURATION_CREATE,
      meta: {
        permission: {
          scope: 'write:system_type',
        },
      },
    },
  ],
} as RouteRecordRaw
