import { useI18n } from 'vue-i18n'
import { AxiosError } from 'axios'
import { UserDisplayableError } from '@gcx-si/vue-components/UserDisplayableError'

import type { AxiosResponse } from 'axios'
import type { GenericObject, Path } from 'vee-validate'
import type { ServerSideValidationHandler } from '@gcx-si/vue-components/useFormSubmitHandler'
import type { ErrorResponse } from '@/api'
import type { App } from 'vue'

import messages from '@/locales/en.json'

const HANDLED_STATUS_CODES = [409, 422]

/**
 * Parse validation responses and display form field errors.
 */
export function useServerSideValidation<FormValuesType extends GenericObject>(): ServerSideValidationHandler<FormValuesType> {
  const { t } = useI18n()

  return function handleServerSideValidation(initialError: unknown, { setFieldError }) {
    const error = shouldHandleError(initialError)

    if (!error) {
      return initialError
    }

    const { fieldErrors } = error

    if (!fieldErrors) {
      return initialError
    }

    for (const fieldError of fieldErrors) {
      const [firstError] = fieldError.errors

      const detail = firstError.messageKey && translationExists(firstError.messageKey)
        ? t(firstError.messageKey, firstError.messageParams)
        : firstError.message

      setFieldError(fieldError.path as Path<FormValuesType>, detail)
    }

    return initialError
  }
}

export function useCustomErrorHandler(app: App) {
  /**
   * For handling any global error coming from backend in a global scope.
   */
  function customErrorHandler(initialError: unknown): UserDisplayableError | null | false {
    const { $t: t } = app.config.globalProperties
    const error = shouldHandleError(initialError)

    if (!error) {
      return false
    }

    const { fieldErrors, globalErrors } = error

    if (fieldErrors && !globalErrors) {
      return false
    }

    if (!fieldErrors && !globalErrors) {
      return new UserDisplayableError({
        severity: 'error',
        life: 5_000,
        detail: t('components.form_submit.unknown_validation_error.detail'),
        summary: t('components.form_submit.unknown_validation_error.summary'),
      })
    }

    for (const globalError of globalErrors) {
      if (globalError.messageKey && translationExists(globalError.messageKey)) {
        return new UserDisplayableError({
          severity: 'warn',
          life: 5_000,
          detail: {
            keypath: globalError.messageKey,
            props: globalError.messageParams,
          },
          summary: t('global.warning'),
        })
      }
    }

    return new UserDisplayableError({
      severity: 'warn',
      life: 5_000,
      detail: globalErrors[0].message,
      summary: t('global.warning'),
    })
  }

  return { customErrorHandler }
}

function translationExists(key: string) {
  return Object.prototype.hasOwnProperty.call(messages, key)
}

function shouldHandleError(error: AxiosError<ErrorResponse> | unknown): ErrorResponse | false {
  if (!(error instanceof AxiosError)) {
    return false
  }

  if (!error.response) {
    return false
  }

  const { status }: AxiosResponse<ErrorResponse> = error.response
  if (!HANDLED_STATUS_CODES.includes(status)) {
    return false
  }

  return error.response.data
}
